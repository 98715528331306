.header {
  text-align: center;

  .top-row {
    max-width: 986px;
    margin-bottom: -48px;

    @media (max-width: 787px) {
      margin-right: 1rem;
    }
  }

  .btn-link {
    cursor: pointer;
    font-weight: 300;
    font-size: x-large;
    padding: 0;
    padding-bottom: 3px;

    &:hover,
    &:focus {
      text-decoration: none;
    }
  }

  h1 {
    color: inherit;
    font-size: 36px;
    margin-top: -10px;
    line-height: 1.6;
    white-space: pre-wrap;
    font-weight: 300;

    @media (max-width: 787px) {
      font-size: 24px;
      margin-bottom: 15px;
      padding: 0 15px;
    }
  }
}

.howitworks-page {
  h1 {
    margin-top: 40px;
  }
}
