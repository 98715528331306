.item-list {
  max-width: 986px;

  @media (max-width: 787px) {
    margin: 0 -2px;
  }
}

.drops-list,
.item-list {
  img {
    width: calc(100vw / 3);
    height: calc(100vw / 3);
    object-fit: cover;
    padding: 1px;

    @media (min-width: 768px) {
      padding: 0 15px 15px;
    }
    @media (min-width: 975px) {
      padding: 0 30px 30px;
      width: 100%;
      height: 310px;
    }
    @media (min-width: 1024px) {
      padding: 0 15px 30px;
    }
  }
}
