.home-page {
  footer a {
    color: #b2b2b2;
    font-size: small;
    padding: 0 5px;
  }

  h2 {
    color: inherit;
    font-size: 36px;
    line-height: 2;
    font-weight: 300;

    @media (max-width: 787px) {
      font-size: 24px;
      margin-bottom: 15px;
      padding: 0 15px;
    }
  }
}
.counter {
  margin: 6rem 0;
  @media (max-width: 468px) {
    margin: 3rem 0;
  }
}
