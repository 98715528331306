.avatar {
  border-radius: 50%;
  width: 100px;
  height: 100px;
}

.user-avatar-inner {
  font-size: xx-large;
  color: #fff;
}

.UserAvatar--inner {
  width: 100%;
  height: 100%;
  line-height: 3;

  @media (min-width: 1024px) {
    line-height: 8;
  }
}
