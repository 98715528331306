.landing-page {
  text-align: center;

  .item-list {
    .UserAvatar,
    img {
      padding: 0;
      height: 100px;
      width: 100px;

      @media (min-width: 1024px) {
        height: 250px;
        width: 250px;
      }
    }
  }

  .all-products {
    padding-top: 10px;
    border-bottom: 1px solid black;
    font-size: 1.4rem;
  }
}
