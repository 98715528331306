// @import "~bootstrap/scss/bootstrap.scss";

$enable-rounded: false;

$theme-colors: (
  'primary': #000,
  'danger': #ff001e,
  'old-blue': #0074d9,
);

$grey: rgba(0, 0, 0, 0.65);

/*!
 * Bootstrap v4.2.1 (https://getbootstrap.com/)
 * Copyright 2011-2018 The Bootstrap Authors
 * Copyright 2011-2018 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
 */

@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins';
@import '~bootstrap/scss/root';
@import '~bootstrap/scss/reboot';
@import '~bootstrap/scss/type';
// @import "~bootstrap/scss/images";
// @import "~bootstrap/scss/code";
@import '~bootstrap/scss/grid';
// @import "~bootstrap/scss/tables";
@import '~bootstrap/scss/forms';
@import '~bootstrap/scss/buttons';
@import '~bootstrap/scss/transitions';
// @import "~bootstrap/scss/dropdown";
// @import "~bootstrap/scss/button-group";
// @import "~bootstrap/scss/input-group";
// @import "~bootstrap/scss/custom-forms";
@import '~bootstrap/scss/nav';
@import '~bootstrap/scss/navbar';
// @import "~bootstrap/scss/card";
// @import "~bootstrap/scss/breadcrumb";
// @import "~bootstrap/scss/pagination";
// @import "~bootstrap/scss/badge";
// @import "~bootstrap/scss/jumbotron";
// @import "~bootstrap/scss/alert";
@import '~bootstrap/scss/progress';
// @import "~bootstrap/scss/media";
// @import "~bootstrap/scss/list-group";
@import '~bootstrap/scss/close';
// @import "~bootstrap/scss/toasts";
@import '~bootstrap/scss/modal';
// @import "~bootstrap/scss/tooltip";
// @import "~bootstrap/scss/popover";
// @import "~bootstrap/scss/carousel";
@import '~bootstrap/scss/spinners';
@import '~bootstrap/scss/utilities';
// @import "~bootstrap/scss/print";

// "@blueprintjs/core": "^3.11.0",
/*!

Copyright 2015-present Palantir Technologies, Inc. All rights reserved.
Licensed under the terms of the LICENSE file distributed with this project.

*/

@import '~@blueprintjs/colors/src/colors';

// Import files in the same order that they are documented in the docs
@import '~@blueprintjs/core/src/common/variables';
@import '~@blueprintjs/core/src/common/mixins';

@import '~@blueprintjs/core/src/reset';
@import '~@blueprintjs/core/src/typography';
// @import '~@blueprintjs/core/src/accessibility';

$pt-border-radius: 0;

// @import '~@blueprintjs/core/src/components/index';
@import '~@blueprintjs/core/src/components/portal/portal';
@import '~@blueprintjs/core/src/components/html-table/html-table';
// @import "~@blueprintjs/core/src/components/panel-stack/panel-stack";
@import '~@blueprintjs/core/src/components/popover/popover';
@import '~@blueprintjs/core/src/components/skeleton/skeleton/';
@import '~@blueprintjs/core/src/components/forms/index';
@import '~@blueprintjs/core/src/components/icon/icon';
@import '~@blueprintjs/core/src/components/button/button';
@import '~@blueprintjs/core/src/components/menu/menu';
@import '~@blueprintjs/select/src/components/select/select';
