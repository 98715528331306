.testimonials {
  &.item-list {
    blockquote {
      padding: 0 1em;

      @media (min-width: 787px) {
        text-align: left;
      }
    }

    img {
      padding: 0;
      height: 100px;
      width: 100px;

      @media (min-width: 1024px) {
        height: 250px;
        width: 250px;
      }
    }
  }
}
