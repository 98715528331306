.howitworks-page {
  padding: 0;

  h1 {
    font-size: x-large;
    font-weight: 300;
  }

  h4 {
    font-style: italic;
    font-weight: 300;
  }

  .store-buttons {
    margin-top: 30px;
    margin-bottom: 30px;
  }
}
