.drops-list {
  margin-top: -4rem;

  .time {
    margin: 2.5rem 0;
  }

  .ant-statistic {
    margin-top: 2rem;
    margin-bottom: 2rem;
  }

  .drop-container {
    max-width: 986px;
    margin-bottom: -30px; // for the last item

    @media (max-width: 787px) {
      margin: 0 -2px;
    }
  }
}
